import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";

// export default Page

const Index = (props) => {
   return (
      <>
         <Helmet
          title="孤獨手冊 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
          meta={[
            {
              name: `description`,
              content: "本場演出由20位創作者共同創作，當您進入演出後，將可以與孤獨詩文互動。",
            },
            {
              property: `og:title`,
              content: "孤獨手冊 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
            },
            {
              property: `og:url`,
              content: 'https://online.home-to-all.com/campfires',
            },
            {
              property: `og:description`,
              content: "本場演出由20位創作者共同創作，當您進入演出後，將可以與孤獨詩文互動。",
            },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/campfires.jpg')
            }
          ]}
         >
         </Helmet>
         <IndexSPA />
         {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;